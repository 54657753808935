import api from "../api";

export const SET_LOADING = "SET_LOADING",
  POST_LOGIN_USUARIO = "POST_LOGIN_USUARIO",
  RESET_INITIAL_VALUES = "RESET_INITIAL_VALUES",
  SET_LOGIN = "SET_LOGIN",
  SET_ACCESSTOKEN = "SET_ACCESSTOKEN",
  LOGOUT = "LOGOUT",
  SET_ROL = "SET_ROL",
  SET_USERID = "SET_USERID",
  SET_COUNTRY = "SET_COUNTRY",
  SET_NAVBAR_ITEM = "SET_NAVBAR_ITEM",
  SET_NAVBAR_STATUS = "SET_NAVBAR_STATUS",
  SET_MENU_MOBILE = "SET_MENU_MOBILE";

// * DISPATCH FUNCS

const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setLogin = (usuario) => ({
  type: POST_LOGIN_USUARIO,
  usuario,
});

export const setLogout = () => ({
  type: RESET_INITIAL_VALUES,
});

export const setCountry = (country) => ({
  type: SET_COUNTRY,
  country,
});

export const setNavbarItem = (listNavbar) => ({
  type: SET_NAVBAR_ITEM,
  listNavbar,
});

export const setNavbarStatus = (navbarStatus) => ({
  type: SET_NAVBAR_STATUS,
  navbarStatus,
});

export const setMenuMobile = (payload) => ({
  type: SET_MENU_MOBILE,
  payload,
});

// * FETCHING FUNCS

export const fecthLogout = () => (dispatch) => {
  dispatch(setLogout());
};

export const fecthLogin = (login) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.apiLoginWeb(login);

    dispatch(setLogin(res.usuario));
    return res;
  } catch (error) {
    console.error("error: ", error);
  }
  dispatch(setLoading(false));
};
